/* Apply background color to the entire body */

/* You can include other global styles here */
@font-face {
  font-family: "Sabon MT W01";
  src: url("https://db.onlinewebfonts.com/t/0501faa73c4e8a64b31b971d2be0aa99.eot");
  src: url("https://db.onlinewebfonts.com/t/0501faa73c4e8a64b31b971d2be0aa99.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/0501faa73c4e8a64b31b971d2be0aa99.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/0501faa73c4e8a64b31b971d2be0aa99.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/0501faa73c4e8a64b31b971d2be0aa99.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/0501faa73c4e8a64b31b971d2be0aa99.svg#Sabon MT W01")
      format("svg");
}

@font-face {
  font-family: "Sabon MT W01 Semibold";
  src: url("https://db.onlinewebfonts.com/t/9eee2e262011447268db3cd4a3bed3fc.eot");
  src: url("https://db.onlinewebfonts.com/t/9eee2e262011447268db3cd4a3bed3fc.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/9eee2e262011447268db3cd4a3bed3fc.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/9eee2e262011447268db3cd4a3bed3fc.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/9eee2e262011447268db3cd4a3bed3fc.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/9eee2e262011447268db3cd4a3bed3fc.svg#Sabon MT W01 Semibold")
      format("svg");
}

@font-face {
  font-family: "Sabon MT W01 Italic";
  src: url("https://db.onlinewebfonts.com/t/f5b5d76abb336f630a6c441bf3a6c252.eot");
  src: url("https://db.onlinewebfonts.com/t/f5b5d76abb336f630a6c441bf3a6c252.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/f5b5d76abb336f630a6c441bf3a6c252.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/f5b5d76abb336f630a6c441bf3a6c252.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/f5b5d76abb336f630a6c441bf3a6c252.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/f5b5d76abb336f630a6c441bf3a6c252.svg#Sabon MT W01 Italic")
      format("svg");
}

/* .box-bottom {
    --mask: 
    linear-gradient(to top,#0000 15px,#000 0),
    radial-gradient(15px,#000 98%,#0000) bottom/27.75px 30px;
  -webkit-mask: var(--mask);
          mask: var(--mask);
  }

  .box-top {
    --mask: 
      linear-gradient(to bottom,#0000 15px,#000 0),
      radial-gradient(15px,#000 98%,#0000) top/27.5px 30px;
    -webkit-mask: var(--mask);
            mask: var(--mask);
  } */

.kothi-text {
  text-align: justify;
  text-align-last: center;
  font-style: oblique;
}

.box-bottom {
  border: 1px solid white;
  padding:8px;
}

.box-inner-bottom {
  border: 1px solid white;
  padding: 15px;
}
.box-inner-sub-bottom {
  border: 1px solid white;
  padding: 10px;
}

.content-text1 {
  background-color: #b67861; /* Light brown background */
  color: #fff; /* White text */
  text-align: justify;
}

.main-box {
  padding:3px;
  background-color: #b67861;
  margin-bottom: 30px;
  margin-top: 20px;
  max-width: 1000px;
}


@media (max-width: 768px) {
 
  .main-box {
    max-width: auto;
  }
  
 
}

/* Mobile Responsive Styles for Small Screens */
@media (max-width: 480px) {
  
  .main-box {
    max-width: auto;
  }
  
  
}
